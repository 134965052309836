import React from 'react';
import { graphql } from 'gatsby';
import PT from 'prop-types';
import { window } from 'browser-monads';
import TemplateWrapper from '../../layouts';
import { encode } from '../../utils';
import {
  Form,
  ParentGrid,
  GridItem,
  MarginExcluder,
} from '../../components/styled-components/structures';
import {
  FormLabel,
  FormInput,
  FormTextarea,
  SubmitButton,
} from '../../components/styled-components/elements';

export default class Contact extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
    formSent: false,
    formSendError: false,
    submitting: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    const { name, email, message } = this.state;

    this.setState({
      formSent: false,
      formSendError: false,
      submitting: true,
    });

    const encoding = encode({
      'form-name': 'contact',
      name,
      email,
      message,
    });

    window
      .fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encoding,
      })
      .then(() => {
        this.setState({
          formSent: true,
          name: '',
          email: '',
          message: '',
          submitting: false,
        });
      })
      .catch((err) => {
        this.setState({
          formSendError: err,
          submitting: false,
        });
      });

    e.preventDefault();
  };

  render() {
    const {
      formSent,
      formSendError,
      submitting,
      name,
      email,
      message,
    } = this.state;
    return (
      <TemplateWrapper>
        <p>Please use the form below to send a message.</p>
        <p>
          Alternatively, call Kate Firth on 07794 050591 or email
          cheshirecommunitychoirs@gmail.com.
        </p>
        {formSendError && (
          <p>
            There was an error sending your message... please try again later.
          </p>
        )}
        {formSent && (
          <p>
            Thanks for your message! I will get back to you as soon as possible.
          </p>
        )}
        <MarginExcluder>
          <Form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="hunnypot"
            onSubmit={this.handleSubmit}
            className="grid-form"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <input name="hunnypot" onChange={this.handleChange} />
            </p>
            <ParentGrid gridRows="2.5em 2.5em 5em 2.5em" gridColumns={3}>
              <GridItem gridArea="1 / 1 / 2 / 2">
                <FormLabel htmlFor="name">Your name: </FormLabel>
              </GridItem>
              <GridItem gridArea="1 / 2 / 2 / 3">
                <FormInput
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  value={name}
                />
              </GridItem>
              <GridItem gridArea="2 / 1 / 3 / 2">
                <FormLabel htmlFor="email">Your email: </FormLabel>
              </GridItem>
              <GridItem gridArea="2 / 2 / 3 / 3">
                <FormInput
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </GridItem>
              <GridItem gridArea="3 / 1 / 4 / 2">
                <FormLabel htmlFor="message">Message: </FormLabel>
              </GridItem>
              <GridItem gridArea="3 / 2 / 4 / 3">
                <FormTextarea
                  name="message"
                  onChange={this.handleChange}
                  value={message}
                />
              </GridItem>
              <GridItem gridArea="4 / 1 / 5 / 3">
                <SubmitButton
                  type="submit"
                  disabled={submitting || !name || !email || !message}
                >
                  Send
                </SubmitButton>
              </GridItem>
            </ParentGrid>
          </Form>
        </MarginExcluder>
      </TemplateWrapper>
    );
  }
}

Contact.propTypes = {
  data: PT.shape({
    site: PT.shape({
      siteMetadata: PT.shape({
        title: PT.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
