export const encode = (data) => {
  const entries = Object.entries(data);
  return entries
    .map(
      ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");
};

export const alphabeticallyByKey = ([key1], [key2]) => {
  if (key1 < key2) return -1;
  if (key2 < key1) return 1;
  return 0;
};
